import { defineStore } from 'pinia'
import { filter, asHTML } from '@prismicio/client';

import { usePrismicClient } from '~/composables/usePrismicClient'
import type { DisclaimerType } from '@/types'

export const useDisclaimerStore = defineStore('disclaimer', () => {
  const { client } = usePrismicClient()
  const { setLoading } = useUIStore()

  const disclaimer = ref<DisclaimerType>()

  const parseDisclaimer = (doc: Record<string, any>): DisclaimerType | undefined => {
    try {
      return {
        content: asHTML(doc.content) || '',
      }
    } catch (error) {
      console.error('Error parsing disclaimer data from Prismic:', error)
    }
  }

  const fetchDisclaimer = async (): Promise<DisclaimerType | undefined> => {
    try {
      setLoading(true)
      const response = await client.get({
        filters: [
          filter.at('document.type', 'disclaimer'),
        ],
      })

      const parsedResponse = parseDisclaimer(response.results.map(({ data, id }) => ({ ...data, id }))[0])

      return Promise.resolve(parsedResponse)
    } catch (error) {
      console.error('Error fetching disclaimer data from Prismic:', error)
      return Promise.resolve(undefined)
    } finally {
      setLoading(false)
    }
  }

  const loadDisclaimer = async (): Promise<boolean> => {
    try {
      disclaimer.value = await fetchDisclaimer()
      return Promise.resolve(true)
    } catch (error) {
      console.error('Error loading disclaimer data from Prismic:', error)
      return Promise.resolve(false)
    }
  }

  return {
    disclaimer,

    loadDisclaimer,
    fetchDisclaimer,
  }
})
